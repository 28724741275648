import React from "react"
import SEO from '../components/SEO.js'
import { Link, graphql } from "gatsby"
import styles from "../styles/blog-post.module.css"
require(`katex/dist/katex.min.css`)


export default ({data}) => {
  const post = data.markdownRemark
  console.log(data)
  return (
      <div>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.excerpt}
          pathname={post.fields.slug}
          article
        />
        <div className={styles.postContainer}>
          <div className={styles.postHead}>
            <div className={styles.titleText + " h2"}>{post.frontmatter.title}</div>
            <div className={styles.dateText + " mb-3"}>
              <div>
                {post.fields.tags.map(tag => (
                        <Link
                          to={`/tags/${tag}`}
                          className={styles.badge + " btn btn-sm mb-2 btn-outline-light mr-2"}
                        >
                          {tag}
                        </Link>
                ))}
              </div>
              Posted by <Link
                  to={`/aboutMe`}
                  className={styles.authorLink}>
                  Daniel White
                  </Link> on {post.frontmatter.date}
            </div>
          </div>
        </div>
        <div className={styles.contentText} dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 300)
      frontmatter {
        title
        date
      }
      fields {
        tags
        slug
      }
    }
  }
`